import { IonButton } from "@ionic/react";
import { FC } from "react";

import "../style.scss";

const LicenseTag: FC<{ agentReq: string }> = ({ agentReq }) => {
  return (
    <IonButton
      disabled
      size="small"
      fill="outline"
      className="qualification-preference-shift-tag"
      data-testid="qualification-preference-shift-tag"
    >
      <span className="qualification-preference-shift-tag-text">{agentReq}</span>
    </IonButton>
  );
};

export { LicenseTag };
