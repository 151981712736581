import { addLicense } from "@src/app/licenseManager/api";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { Worker } from "@src/appV2/Worker/api/types";
import moment from "moment-timezone";

import {
  LICENSES_SUMMARY_TEXT_COLORS,
  LICENSE_STATUSES_VALUE_MAPPING,
  SUMMARY_TEXT_LICENSE_STATUSES_ORDER,
  logType,
} from "./constants";
import { ICreateLicense, ILicenseDetails, LicenseDefaultActiveRule } from "./interfaces";
import { updatePreference } from "../openShifts/api";

export const getLicensesCountByStatus = (
  licenses: ILicenseDetails[]
): { [key in LicenseStatuses]?: number } => {
  const licensesCountByStatus = {};
  licenses.forEach((license) => {
    const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days", true);
    let { status } = license;
    if (daysUntilExpired <= 0) {
      status = LicenseStatuses.EXPIRED;
    } else if (daysUntilExpired <= 90 && license.status === LicenseStatuses.ACTIVE) {
      // Expiring in 3 months
      status = LicenseStatuses.EXPIRING;
    }

    if (!status) {
      return;
    }

    if (status && !(status in licensesCountByStatus)) {
      licensesCountByStatus[status] = 0;
    }
    licensesCountByStatus[status] += 1;
  });
  return licensesCountByStatus;
};

export const getLicensesSummaryText = (licensesCountByStatus: {
  [key in LicenseStatuses]?: number;
}): JSX.Element[] => {
  const summaryText: JSX.Element[] = [];
  for (const licenseStatus of SUMMARY_TEXT_LICENSE_STATUSES_ORDER) {
    if (licensesCountByStatus[LicenseStatuses[licenseStatus]]) {
      if (
        LicenseStatuses[licenseStatus] === LicenseStatuses.EXPIRING &&
        licensesCountByStatus[LicenseStatuses.ACTIVE]
      ) {
        // Ignoring EXPIRING one as we are adding it with ACTIVE status
        continue;
      }
      if (LicenseStatuses[licenseStatus] === LicenseStatuses.ARCHIVED) {
        // Ignoring ARCHIVED
        continue;
      }

      if (
        LicenseStatuses[licenseStatus] === LicenseStatuses.ACTIVE &&
        licensesCountByStatus[LicenseStatuses.EXPIRING]
      ) {
        // Logic to include active & expiring summary text
        summaryText.push(
          <>
            <span style={{ color: LICENSES_SUMMARY_TEXT_COLORS.ACTIVE }}>
              {(licensesCountByStatus[LicenseStatuses.ACTIVE] || 0) +
                (licensesCountByStatus[LicenseStatuses.EXPIRING] || 0)}{" "}
              {LICENSE_STATUSES_VALUE_MAPPING[LicenseStatuses.ACTIVE]}
            </span>
            &nbsp;
            <span style={{ color: LICENSES_SUMMARY_TEXT_COLORS.EXPIRING }}>
              ({licensesCountByStatus[LicenseStatuses.EXPIRING]}{" "}
              {LICENSE_STATUSES_VALUE_MAPPING[LicenseStatuses.EXPIRING]})
            </span>
          </>
        );
      } else {
        summaryText.push(
          <span style={{ color: LICENSES_SUMMARY_TEXT_COLORS[licenseStatus] }}>
            {licensesCountByStatus[LicenseStatuses[licenseStatus]]}{" "}
            {LICENSE_STATUSES_VALUE_MAPPING[LicenseStatuses[licenseStatus]]}
          </span>
        );
      }
    }
  }
  return summaryText;
};

export const updateLicenseAndQualificationPreference = async (
  qualification: string,
  userId: string
): Promise<Worker | undefined> => {
  const LOG_CONTEXT = "updateLicenseAndQualificationPreference";

  const license = {
    qualification,
    state: "Any",
    multiState: false,
    number: "",
    logType: logType.IMPORT,
  };
  try {
    await addLicense(license, userId, true);
    return await updatePreference("qualification", qualification, true);
  } catch (error) {
    deprecatedDoNotUseLogError({
      message: `${LOG_CONTEXT} Error while updating license and qualification preference userId: ${userId}, qualification:- ${qualification}. Error:- ${error?.message}`,
    });
    throw error;
  }
};

interface IsLicenseDefaultActiveParams {
  defaultActiveLicenseRules: LicenseDefaultActiveRule[];
  license: Pick<ICreateLicense, "qualification" | "state">;
}
export function isLicenseDefaultActive(params: IsLicenseDefaultActiveParams): boolean {
  const { license, defaultActiveLicenseRules } = params;
  const checkRulePredicate = (rule: LicenseDefaultActiveRule) =>
    Object.entries(rule).every((ruleEntry) =>
      ruleEntry[1].some((value) => value === license[ruleEntry[0]])
    );
  return defaultActiveLicenseRules.some(checkRulePredicate);
}
