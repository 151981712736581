import { Text, UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { CommonDialog } from "@src/appV2/lib";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { useHistory } from "react-router-dom";

export interface DuplicatedLicenseModalProps {
  modalState: UseModalState;
  existingLicenseStatus?: LicenseStatuses;
}

export function DuplicatedLicenseModal({
  modalState,
  existingLicenseStatus,
}: DuplicatedLicenseModalProps) {
  const history = useHistory();

  const onCancel = () => {
    history.replace(TabRouterPath.LICENSE_MANAGER, {
      forceReload: true,
    });
  };

  const onEdit = () => {
    modalState.closeModal();
  };

  return (
    <CommonDialog
      modalState={modalState}
      title="This license exists"
      actions={
        <>
          <Button
            variant="contained"
            fullWidth
            onClick={onEdit}
            aria-label="Edit Duplicated License"
          >
            Make changes
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={onCancel}
            aria-label="Cancel License Creation"
          >
            Cancel
          </Button>
        </>
      }
    >
      <Text textAlign="center" paragraph>
        {existingLicenseStatus === LicenseStatuses.ACTIVE
          ? "You already have a license for this state and worker type. Please enter new license info!"
          : "You’ve already added a license for this state and worker type. Please allow time for the license to be verified!"}
      </Text>
    </CommonDialog>
  );
}
