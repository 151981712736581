import "./style.scss";
import { useModalState } from "@clipboard-health/ui-react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { useGetLicenseDetails } from "@src/appV2/Licenses/api/useGetLicenseDetails";
import { getLicensesCountUrl } from "@src/appV2/Licenses/api/useGetLicensesCount";
import { useGetQualifications } from "@src/appV2/Qualifications/api/useGetQualifications";
import { getQualificationLabel } from "@src/appV2/Qualifications/getQualificationLabel";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants/userEvents";
import { logApiFailureEvent } from "@src/lib/analytics";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { softDeleteLicense } from "./api";
import {
  LICENSE_DETAILS_HEADER_TEXT,
  LICENSE_EXPIRY_DATE_COLORS,
  LICENSE_STATUSES_VALUE_MAPPING,
} from "./constants";
import { RemoveLicenseDialog } from "./removeLicense";
import { TabRouterPath } from "../routing/constant/tabRoute";

export function LicenseDetails() {
  const history = useHistory();
  const { tmz: agentTimezone, userId } = useDefinedWorker();
  const removeLicenseDialogState = useModalState();
  const [deletingLicense, setDeletingLicense] = useState<boolean>(false);
  const { data: qualificationsData, isLoading: qualificationsAreLoading } = useGetQualifications({
    enabled: true,
  });

  const { licenseId } = useParams<{ licenseId: string }>();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isSuccess,
    data: license,
  } = useGetLicenseDetails(
    {
      licenseId,
      workerId: userId ?? "",
    },
    {
      userErrorMessage: "Something went wrong while loading your license details",
      enabled: Boolean(userId),
    }
  );

  const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days", true);

  const qualificationLabel = getQualificationLabel(
    license?.qualification ?? "",
    qualificationsData?.data ?? []
  );

  useLogEffect(
    USER_EVENTS.LICENSE_VIEWED,
    {
      licenseStatus: license?.status,
      daysUntilExpired,
    },
    { enabled: isSuccess }
  );

  const showReplacementLicenseButton = useMemo(() => {
    if (!license) {
      return false;
    }
    if (daysUntilExpired <= 90 && license.status === LicenseStatuses.ACTIVE) {
      return true;
    }
    return [LicenseStatuses.EXPIRED, LicenseStatuses.EXPIRING, LicenseStatuses.REJECTED].includes(
      license?.status
    );
  }, [daysUntilExpired, license]);

  const onAddReplacementLicenseClick = () => {
    logEvent(USER_EVENTS.ADD_LICENSE_TAPPED, {
      replacement: true,
      old_license_id: license?.id,
      old_license_status: license?.status,
      days_until_expired: daysUntilExpired,
      qualification: license?.qualification,
    });
    history.push(TabRouterPath.ADD_LICENSE, { license });
  };

  const headerTextContent = useMemo(() => {
    let headerText = LICENSE_DETAILS_HEADER_TEXT.ACTIVE;
    if (license?.status === LicenseStatuses.PENDING) {
      headerText = LICENSE_DETAILS_HEADER_TEXT.PENDING.replace(
        "{LICENSE_CREATED_AT}",
        moment(license?.createdAt).tz(String(agentTimezone)).format("MM/DD/YYYY [at] hh:mm A")
      );
    } else if (daysUntilExpired <= 0 || license?.status === LicenseStatuses.EXPIRED) {
      headerText = LICENSE_DETAILS_HEADER_TEXT.EXPIRED;
      if (license?.hasPendingLicense) {
        headerText = LICENSE_DETAILS_HEADER_TEXT.EXPIRED_AND_HAS_PENDING_LICENSE;
      }
    } else if (license?.status === LicenseStatuses.REJECTED) {
      headerText = LICENSE_DETAILS_HEADER_TEXT.REJECTED.replace(
        "{LICENSE_REJECTED_AT}",
        moment(license?.rejectedAt).tz(String(agentTimezone)).format("MM/DD/YYYY [at] hh:mm A")
      );
      if (license?.hasPendingLicense) {
        headerText = LICENSE_DETAILS_HEADER_TEXT.REJECTED_AND_HAS_PENDING_LICENSE.replace(
          "{LICENSE_REJECTED_AT}",
          moment(license?.rejectedAt).tz(String(agentTimezone)).format("MM/DD/YYYY [at] hh:mm A")
        );
      }
    }
    return headerText;
  }, [agentTimezone, daysUntilExpired, license]);

  const computedLicenseProps = useMemo(() => {
    const licenseProps = {
      status: license?.status,
      expirationTextColor: "",
    };
    if (daysUntilExpired <= 0 || license?.status === LicenseStatuses.EXPIRED) {
      licenseProps.status = LicenseStatuses.EXPIRED;
      licenseProps.expirationTextColor = LICENSE_EXPIRY_DATE_COLORS.EXPIRED;
    } else if (daysUntilExpired <= 90 && license?.status === LicenseStatuses.ACTIVE) {
      licenseProps.status = LicenseStatuses.EXPIRING;
      licenseProps.expirationTextColor = LICENSE_EXPIRY_DATE_COLORS.EXPIRING;
    }
    return licenseProps;
  }, [daysUntilExpired, license?.status]);

  const handleDeleteLicense = () => {
    (async () => {
      try {
        removeLicenseDialogState.closeModal();
        setDeletingLicense(true);
        await softDeleteLicense(licenseId, String(userId));

        // refetch licenses count and worker (to get updated worker qualification) on successfull removal
        await queryClient.invalidateQueries([getLicensesCountUrl(String(userId))]);
        await queryClient.invalidateQueries([GET_WORKER_PATH]);

        logEvent(USER_EVENTS.LICENSE_REMOVED, {
          licenseStatus: license?.status,
          daysUntilExpired,
        });
        history.replace(`/home/account/licenses`);
      } catch (error) {
        logApiFailureEvent(error);
        showErrorToast("Error while deleting license.");
      } finally {
        setDeletingLicense(false);
      }
    })();
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading || qualificationsAreLoading} backdropDismiss={true} />
      {isSuccess && computedLicenseProps.status ? (
        <>
          <IonHeader no-border>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton text="" defaultHref="/home/account/licenses" mode="ios" />
              </IonButtons>
              <IonTitle data-testid="license-details-title">{`${qualificationLabel} License (${
                LICENSE_STATUSES_VALUE_MAPPING[computedLicenseProps.status]
              })`}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="license-details">
            <IonText className="license-details-header" data-testid="license-details-header-text">
              {headerTextContent}
            </IonText>
            <IonCard className="license-details-card">
              <IonCardContent className="license-details-card-content">
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>TYPE</p>
                    <IonText>
                      <h3>{qualificationLabel}</h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>STATE</p>
                    <IonText>
                      <h3>
                        {license.state} {license.multiState && "(multi-state)"}
                      </h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>LICENSE NUMBER</p>
                    <IonText>
                      <h3>{license.number || "--"}</h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>EXPIRATION DATE</p>
                    <IonText
                      style={{
                        color: computedLicenseProps.expirationTextColor,
                      }}
                      data-testid="license-expiration-date"
                    >
                      <h3>
                        {license.expiresAt ? moment(license.expiresAt).format("MM/DD/YYYY") : "--"}
                      </h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => {
                removeLicenseDialogState.openModal();
              }}
              disabled={deletingLicense}
            >
              {deletingLicense ? (
                <IonSpinner name="lines" data-testid="delete-license-button-loading" />
              ) : (
                "Remove License"
              )}
            </Button>

            {showReplacementLicenseButton && (
              <IonFab vertical="bottom" slot="fixed" className="add-license-fab">
                <IonButton
                  expand="block"
                  className="add-replacement-button"
                  fill="outline"
                  data-testid="add-replacement-button"
                  onClick={onAddReplacementLicenseClick}
                >
                  Add Replacement License
                </IonButton>
              </IonFab>
            )}
          </IonContent>
        </>
      ) : (
        <IonText className="ion-text-center">
          <p style={{ marginTop: "20px" }}>
            {isLoading
              ? "Loading License Details..."
              : "Something went wrong while loading your license details"}
          </p>
        </IonText>
      )}
      <RemoveLicenseDialog modalState={removeLicenseDialogState} onSubmit={handleDeleteLicense} />
    </IonPage>
  );
}
