import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { getWorkerQualifications } from "@src/utils/agent";
import { useMemo } from "react";

export function useWorkerQualifications() {
  const worker = useDefinedWorker();

  const workerQualifications = useMemo((): string[] => {
    return getWorkerQualifications(worker);
  }, [worker]);

  return workerQualifications;
}
