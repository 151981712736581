import { LicensesCount, useGetLicensesCount } from "@src/appV2/Licenses/api/useGetLicensesCount";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

export function useLicenseCount(): {
  licensesCount: LicensesCount;
  licensesNotificationCount: number;
} {
  const { userId } = useDefinedWorker();
  const {
    data: licensesCount = {
      PENDING: 0,
      ACTIVE: 0,
      EXPIRING: 0,
      EXPIRED: 0,
      REJECTED: 0,
    },
  } = useGetLicensesCount({
    workerId: userId,
  });

  const expiredLicenses = licensesCount.EXPIRED || 0;
  const expiringLicenses = licensesCount.EXPIRING || 0;
  const rejectedLicenses = licensesCount.REJECTED || 0;
  const licensesNotificationCount = rejectedLicenses + expiringLicenses + expiredLicenses;

  return { licensesCount, licensesNotificationCount };
}
